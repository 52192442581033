@import "~src/assets/scss/vars";
@import url('https://fonts.googleapis.com/css2?family=Comfortaa:wght@300;400;500;600;700&display=swap');

* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

a {
  color: $primaryColor;
  text-decoration: none;
}

body {
  font-size: 14px;
  background: #eef0f8;
  color: $secondaryColor;
  font-family: Comfortaa, sans-serif !important;
}

.page-container {
  padding: 20px 50px;
}

.page-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  background: #fff;
  box-shadow: 0 10px 30px 0 rgba(82, 63, 105, .08);
  padding: 20px 50px;

  h2 {
    font-size: 20px;
    margin: 0;
  }

  .page-header-options {

  }
}

.page-content-container {
  position: relative;
  box-shadow: 0 0 30px 0 rgba(82, 63, 105, .05);
  border-radius: 5px;
  background: #fff;
  padding: 20px;
  margin: 20px 0;
  min-height: 150px;

  h3 {
    font-weight: 700;
  }
}

.custom-table {
  width: 100%;
}

.form-row {
  mat-form-field {
    width: 100%;
  }
}

.form-row-half {
  float: left;
  width: calc(50% - 15px);

  &:nth-of-type(odd) {
    margin-right: 30px;
  }

  mat-form-field {
    width: 100%;
  }
}

.form-footer {
  text-align: right;

  .mat-flat-button {
    margin-left: 15px;
  }
}

.text-caption {
  color: $secondaryLightenColor;
  line-height: 1.4;
  margin: 0 0 20px 0 !important;
}

.container-loader {
  display: flex;
  justify-content: center;
  align-items: center;
  position: absolute;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  background: rgba(#eef0f8, 0.7);
  z-index: 999;

  &.relative {
    position: relative;
    height: 300px;
  }
}

.clearfix {
  &:after {
    clear: both;
    display: table;
    content: '';
  }
}

.mat-header-cell {
  font-weight: 600;
  color: $secondaryColor;
}

.mat-row {
  transition: all .25s ease-in-out;

  &:hover {
    background: rgba(#eef0f8, 0.5);
  }
}

.mat-row .mat-cell {
  font-size: 12px;
}

.responsive-table {
  overflow: auto;
  width: 100%;

  .custom-table {
    min-width: 1000px;
  }
}

.custom-table th {
  > div {
    font-weight: 700;
    text-transform: uppercase;
    font-size: 12px;
  }

  mat-form-field {
    width: calc(100% - 20px);
  }
}

.custom-table td {
  padding-right: 25px !important;
  color:$secondaryLightenColor;
  font-size: 12px;
}

.text-overflow {
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
  width: 100%;
  display: block;
}

.text-right {
  text-align: right;
}

.mat-button, .mat-icon-button, .mat-stroked-button, .mat-flat-button {
  border-radius: 20px !important;
  font-size: 13px;
}
